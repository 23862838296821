import { useEffect, useState } from 'react'

export const useConfigService = () => {
  const [openSidebar, setOpenSidebar] = useState(true)

  useEffect(() => {
    const initTheme = localStorage.getItem('openSidebar') || 'true'
    setOpenSidebar(initTheme === 'true')
  }, [openSidebar])

  const toOpenSideBar = () => {
    localStorage.setItem('openSidebar', 'true')
    setOpenSidebar(true)
  }

  const toCloseSideBar = () => {
    localStorage.setItem('openSidebar', 'false')
    setOpenSidebar(false)
  }

  return {
    toOpenSideBar,
    toCloseSideBar,
    openSidebar,
  }
}
