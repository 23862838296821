import React, { useContext } from 'react'

type configContextType = {
  toOpenSideBar: () => undefined
  toCloseSideBar: () => undefined
  openSidebar: false
}

const configContextDefaultValues: configContextType = {
  toOpenSideBar: () => undefined,
  toCloseSideBar: () => undefined,
  openSidebar: false,
}

export const ConfigContext = React.createContext<configContextType>(configContextDefaultValues)

export const ConfigContextProvider = ({ children, ...props }: any) => {
  return <ConfigContext.Provider {...props}>{children}</ConfigContext.Provider>
}

export const useConfigContext = () => useContext(ConfigContext)
