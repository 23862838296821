import { ICity, ICountry, IState } from '@/interface/user.interface'
import httpService from '@/services/httpService'

export const useBaseService = () => {
  const getCountries = async (): Promise<ICountry[]> => {
    const res: { data: ICountry[] } = await httpService.get('/v1/base/country')
    return Promise.resolve(res.data)
  }
  const getStates = async (countryId: number): Promise<IState[]> => {
    const res: { data: IState[] } = await httpService.get(`/v1/base/state?${countryId}`)
    return Promise.resolve(res.data)
  }
  const getCities = async (stateId: number): Promise<ICity[]> => {
    const res: { data: ICity[] } = await httpService.get(`/v1/base/city?stateId=${stateId}`)
    return Promise.resolve(res.data)
  }

  return {
    getCountries,
    getStates,
    getCities,
  }
}
